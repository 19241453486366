.container {
  display: flex;
  gap: 5px;
  margin: 6px 0 6px 12px;

  .dropdownSortBy {
    width: 180px;
  }
}

.dropdown {
  width: 170px;
}
