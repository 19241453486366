/* ============ Gotham Pro ============ */
@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-Bold.eot');
    src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
    url('./GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-Bold.woff2') format('woff2'),
    url('./GothamPro/GothamPro-Bold.woff') format('woff'),
    url('./GothamPro/GothamPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-Light.eot');
    src: local('Gotham Pro Light'), local('GothamPro-Light'),
    url('./GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-Light.woff2') format('woff2'),
    url('./GothamPro/GothamPro-Light.woff') format('woff'),
    url('./GothamPro/GothamPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-Black.eot');
    src: local('Gotham Pro Black'), local('GothamPro-Black'),
    url('./GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-Black.woff2') format('woff2'),
    url('./GothamPro/GothamPro-Black.woff') format('woff'),
    url('./GothamPro/GothamPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-Italic.eot');
    src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
    url('./GothamPro/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-Italic.woff2') format('woff2'),
    url('./GothamPro/GothamPro-Italic.woff') format('woff'),
    url('./GothamPro/GothamPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro Narrow';
    src: url('./GothamPro/GothamProNarrow-Bold.eot');
    src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
    url('./GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamProNarrow-Bold.woff2') format('woff2'),
    url('./GothamPro/GothamProNarrow-Bold.woff') format('woff'),
    url('./GothamPro/GothamProNarrow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-Medium.eot');
    src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
    url('./GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-Medium.woff2') format('woff2'),
    url('./GothamPro/GothamPro-Medium.woff') format('woff'),
    url('./GothamPro/GothamPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-BoldItalic.eot');
    src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'),
    url('./GothamPro/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-BoldItalic.woff2') format('woff2'),
    url('./GothamPro/GothamPro-BoldItalic.woff') format('woff'),
    url('./GothamPro/GothamPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro.eot');
    src: local('Gotham Pro'), local('GothamPro'),
    url('./GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro.woff2') format('woff2'),
    url('./GothamPro/GothamPro.woff') format('woff'),
    url('./GothamPro/GothamPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-MediumItalic.eot');
    src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'),
    url('./GothamPro/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-MediumItalic.woff2') format('woff2'),
    url('./GothamPro/GothamPro-MediumItalic.woff') format('woff'),
    url('./GothamPro/GothamPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-BlackItalic.eot');
    src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'),
    url('./GothamPro/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-BlackItalic.woff2') format('woff2'),
    url('./GothamPro/GothamPro-BlackItalic.woff') format('woff'),
    url('./GothamPro/GothamPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro Narrow';
    src: url('./GothamPro/GothamProNarrow-Medium.eot');
    src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
    url('./GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamProNarrow-Medium.woff2') format('woff2'),
    url('./GothamPro/GothamProNarrow-Medium.woff') format('woff'),
    url('./GothamPro/GothamProNarrow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('./GothamPro/GothamPro-LightItalic.eot');
    src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'),
    url('./GothamPro/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./GothamPro/GothamPro-LightItalic.woff2') format('woff2'),
    url('./GothamPro/GothamPro-LightItalic.woff') format('woff'),
    url('./GothamPro/GothamPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* ============ Breakers Slab W00 ============ */

@font-face {
    font-family: 'Breakers Slab W00';
    src: local('Breakers Slab W00 Bold'), local('BreakersSlabW00-Bold'),
    url('./BreakersSlabW00/BreakersSlabW00-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Breakers Slab W00';
    src: local('Breakers Slab W00 Light'), local('BreakersSlabW00-Light'),
    url('./BreakersSlabW00/BreakersSlabW00-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* ============ Roboto Condensed ============ */

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
    url('./Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'),
    url('./Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
    url('./Roboto_Condensed/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'),
    url('./Roboto_Condensed/RobotoCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'),
    url('./Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'),
    url('./Roboto_Condensed/RobotoCondensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}