@use 'src/resources/styles/variables';

.logo {
  font-family: 'Gotham Pro', sans-serif !important;
  font-size: variables.$fontSizeLg;
  color: variables.$mainDarkColor;
  font-weight: 600;
}

.highlighted {
  font-family: 'Gotham Pro', sans-serif !important;
  color: variables.$primaryColor;
}
