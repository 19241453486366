@use 'src/resources/styles/variables';

.badge {
  height: 32px;
  width: 108px;
  background-color: variables.$primaryColor;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;

  span {
    color: rgba(variables.$lightTextColor, 0.87);
    font-size: 13.75px;
    font-weight: bold;
    letter-spacing: 0.1px;
    text-align: center;
  }
}
