@use 'src/resources/styles/variables';
.headStyle {
  height: 53%;
  padding: 0 17px;
}

.projectCard {
  height: 358px;
  border: none;
  border-radius: 8px;
  padding: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .details {
    padding: 11px 16px;
    span {
      display: inline-block;
    }
    .projectTitle {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: 'Breakers Slab W00', serif !important;
      color: variables.$lightTextColor;
      font-size: variables.$fontSizeMd;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
    .projectCountry {
      display: flex;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        display: inline-block;
        margin-left: 8px;
        margin-top: 2px;
        color: rgba(variables.$lightTextColor, 0.6);
        font-size: variables.$fontSizeSm;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
      }
    }
    .costPerTon {
      margin-top: 5px;
      display: block;
      color: variables.$primaryColor;
      font-size: variables.$fontSizeSm;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 24px;
    }
    .costInUsd {
      color: rgba(255, 255, 255, 0.6);
      font-size: variables.$fontSizeXs;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }
  :global {
    .ant-card-head {
      height: 53%;
      padding: 0 17px;
      border-bottom: none;
    }
    .ant-card-body {
      position: relative;
      bottom: 0;
      height: 48%;
      border-radius: 0 0 8px 8px;
      padding: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
    }
  }
  .extraContent {
    height: 45px;
    line-height: 45px;
    width: 100%;
    border-top: 1px solid rgba(variables.$lightTextColor, 0.2);
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 1px;
    .typeBox {
      display: flex;
      gap: 13px;
    }
    div {
      color: variables.$lightTextColor;
      font-size: variables.$fontSizeSm;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 45px !important;
      margin-right: 8px;
      vertical-align: baseline;
      padding: 0 16px;
    }
    img {
      width: 18px;
      height: 18px;
      // makes svg light grey
      filter: invert(100%) brightness(55%);
    }
  }
  .auctionLabel {
    position: absolute;
    right: 16px;
    bottom: 107%;
    width: auto;
    height: 32px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .auctionInfo {
      color: variables.$mainLightColor;
      font-size: 12px;
      line-height: 18px;
      display: flex;
    }
  }
}

.extraContent {
  height: 45px;
  line-height: 45px;
  width: 100%;
  border-top: 1px solid rgba(variables.$lightTextColor, 0.2);
  border-radius: 0 0 8px 8px;
  position: absolute;
  bottom: 1px;
  .typeBox {
    display: flex;
    gap: 13px;
    white-space: nowrap;
    overflow: hidden;
  }
  div {
    color: variables.$lightTextColor;
    font-size: variables.$fontSizeSm;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 45px !important;
    margin-right: 8px;
    vertical-align: baseline;
    padding: 0 16px;
  }
  img {
    width: 18px;
    height: 18px;
    // makes svg light grey
    filter: invert(100%) brightness(55%);
  }
}

.flag {
  width: 24px;
  height: 24px;
}

.titleTooltip {
  max-width: 500px;
}

.comment {
  position: absolute;
  color: variables.$lightTextColor4;
  top: 20%;
  left: 0;
  width: 256px;
  padding: 20px;
  text-align: center;
}

.extraWide {
  width: 100%;
  height: 644px;
  :global {
    .ant-card-head {
      height: 64%;
    }
    .ant-card-body {
      height: 36.5%;
    }
  }
  .details {
    padding: 24px;
  }
  .projectTitle {
    font-size: variables.$headline2FontSize !important;
  }
  .costPerTon {
    position: absolute;
    right: 24px;
    top: 16px;
  }
  .projectCountry {
    margin: 10px 0;
  }
  .extraContent {
    height: 88px;
    display: flex;
    padding: 16px 24px 16px 0;
    .typeBox {
      padding: 0 0 0 20px !important;
      gap: 0;
    }
    .textBox {
      line-height: 24px !important;
      padding: 0 18px;
      .title {
        font-weight: 300 !important;
      }
      span {
        display: block;
      }
    }
    div:not(:last-child) {
      border-right: 1px solid rgba(variables.$mainLightColor, 0.12);
    }
  }
}

.wide {
  width: 100%;
  height: 310px;
  .details {
    padding: 15px;
  }
  .costPerTon {
    position: absolute;
    right: 15px;
    top: 8px;
  }
  .projectCountry {
    margin: 10px 0;
  }
  .extraContent {
    height: 52px;
    display: flex;
    padding: 12px 16px 12px 0;
    div {
      line-height: 24px !important;
      &:not(:last-child) {
        border-right: 1px solid rgba(variables.$mainLightColor, 0.12);
      }
    }
    .textBox {
      display: flex;
      flex-direction: column;
    }
  }
}
