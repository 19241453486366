@use 'src/resources/styles/variables';

.modal {
  width: 420px !important;
  :global {
    .ant-modal-header {
      background-color: transparent;
      padding: 32px 24px 0 24px;
      border: none;
      border-radius: 8px;
    }

    .ant-modal-title {
      font-size: variables.$fontSizeLg;
      color: variables.$mainDarkColor;
      font-weight: bold;
    }

    .ant-modal-content {
      background-color: rgba(variables.$mainLightColor, 0.8);
      backdrop-filter: blur(37px);
      border-radius: 8px;
    }

    .ant-modal-body {
      background-color: transparent;
      color: variables.$mainDarkColor;
      font-size: variables.$fontSizeMd;
      font-weight: 300;
      padding: 18px 24px 0 24px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      border: none;
      padding: 24px;
    }
  }
}

.cancel {
  background-color: rgba(variables.$mainLightColor, 0.4);
  border: none;
  border-radius: 8px;
  color: variables.$mainDarkColor;
  font-size: variables.$fontSizeSm;
  font-weight: 300;
  padding: 8px 16px;
  flex-basis: 50%;
  height: 50px;

  &:active,
  &:focus,
  &:hover {
    color: variables.$mainDarkColor;
    background-color: rgba(variables.$mainLightColor, 0.2);
  }

  &[disabled],
  &[disabled]:hover {
    color: rgba(variables.$lightTextColor, 0.6);
    background-color: transparent;
    opacity: 0.5;
  }
}

.ok {
  background-color: variables.$primaryColor;
  border: none;
  border-radius: 8px;
  color: variables.$mainLightColor;
  font-size: variables.$fontSizeSm;
  font-weight: bold;
  padding: 8px 16px;
  flex-basis: 50%;
  height: 50px;

  &:active,
  &:focus,
  &:hover {
    color: variables.$mainLightColor;
    background-color: variables.$primaryColor;
  }
}
