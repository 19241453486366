@use 'src/resources/styles/variables';

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  :global {
    .ant-tag {
      margin: 5px !important;
    }
  }
}

.tag {
  display: flex;
  height: 32px;
  align-items: center;
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  font-size: variables.$fontSizeSm;
  cursor: pointer;
  color: variables.$lightTextColor;
  line-height: 20px;
  letter-spacing: 0.25px;

  span {
    padding-left: 5px;
  }
}

.tagsHeader {
  display: flex;
  align-items: center;
  font-size: variables.$fontSizeSm;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.6);
  width: max-content;
}
