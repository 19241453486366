@use 'resources/styles/variables';

.button {
  margin-left: 12px;
}

.logInButton {
  margin-left: 5px;
  background: variables.$primaryColor;
  color: variables.$mainLightColor;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.signUpButton {
  margin-left: 12px;
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
