@use 'src/resources/styles/variables';
.loaderContainer {
  width: 100%;
  height: 85vh;
  :global {
    .ant-spin {
      position: relative !important;
      left: 50%;
      top: 50%;
      &-dot {
        .ant-spin-dot-item {
          opacity: 1 !important;
        }
        .ant-spin-dot-item:nth-child(-n + 2) {
          background-color: variables.$primaryColor !important;
        }
        .ant-spin-dot-item:nth-last-of-type(-n + 2) {
          background-color: variables.$primaryColor !important;
        }
      }
    }
  }
}

.suspense {
  height: 100vh !important;
  background-color: variables.$mainLightColor !important;
}
