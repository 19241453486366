@use 'src/resources/styles/variables';

.button {
  width: 100%;
  background-color: variables.$primaryColor;
  color: variables.$mainLightColor;
  border: none;
  border-radius: 4px;
  text-transform: none;
  font-size: variables.$subtitle2FontSize;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0px 8px 22px rgba(107, 187, 174, 0.3);

  &:hover {
    background-color: rgba(variables.$primaryColor, 0.6);
    color: variables.$mainLightColor;
  }

  &:active,
  &:focus {
    background-color: variables.$primaryColor;
    color: variables.$mainLightColor;
  }

  &:global(.ant-btn-lg) {
    height: 48px;
  }
}
