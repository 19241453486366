@use 'src/resources/styles/variables';
.eventCard {
  width: 673px;
  height: fit-content;
  border: none;
  border-radius: 8px;
  padding: 0;
  position: relative;
  background: rgba(variables.$mainLightColor, 0.3);
  backdrop-filter: blur(37px);

  :global {
    .ant-card-head {
      height: 30%;
      width: 100%;
      padding: 0 17px;
      border-bottom: 1px solid rgba(variables.$mainLightColor, 0.4);
    }

    .ant-card-body {
      padding: 11px 16px 0px 16px;
    }

    .ant-card-extra {
      padding: 12px 0 12px 16px !important;
    }
  }

  .projectCountry {
    display: flex;
    align-content: center;
    justify-content: center;

    .flag {
      width: 24px;
      height: 24px;
    }

    span {
      display: inline-block;
      margin-left: 8px;
      color: rgba(variables.$mainDarkColor, 0.6);
      font-size: variables.$subtitle1FontSize;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
  }

  .dateBlock {
    display: flex;
    align-items: flex-start;

    .span {
      display: inline-block;
    }
    .date {
      margin-left: 11px;
      line-height: 20px;
      color: rgba(variables.$mainDarkColor, 0.6);
      font-size: variables.$fontSizeMd;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
    img {
      // makes svg green
      filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
    }
  }

  .title {
    color: variables.$mainDarkColor;
    font-size: variables.$fontSizeMd;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .description {
    margin-top: 10px;
    color: variables.$mainDarkColor;
    font-size: variables.$subtitle1FontSize;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  &:hover {
    background: rgba(variables.$mainLightColor, 0.6);
  }
}
