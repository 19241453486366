@use 'src/resources/styles/variables';

.tokenBalance {
  max-width: 220px;
  margin: 4px auto 14px auto;
  padding: 10px;
  background-color: rgba(variables.$darkBgColor3, 0.05);
  color: variables.$mainDarkColor;
  font-size: variables.$fontSizeSm;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
}

.tokenTicker {
  font-family: 'Gotham Pro', sans-serif !important;
  font-weight: 700;
}
