@use 'src/resources/styles/variables';

.menu {
  padding: 0;

  :global {
    .ant-menu-item {
      margin: 0 !important;
      padding: 0 0 0 17px !important;
      overflow: visible;
      height: auto;
      line-height: 30px;

      span {
        img {
          opacity: 0.2;
        }
      }

      &:last-child {
        .ant-menu-title-content {
          border-bottom: none;
        }
      }
    }

    .ant-menu-title-content {
      display: inline-block !important;
      line-height: 30px;
      font-size: variables.$fontSizeMd;
      font-weight: 500;
      margin-left: 13px !important;
      padding: 14px 0 12px 0;
      border-bottom: 1px solid rgba(variables.$mainLightColor, 0.6);

      a {
        color: variables.$mainDarkColor !important;
        transition: variables.$defaultTransition !important;

        &:focus,
        &:hover {
          color: variables.$primaryColor !important;
        }
      }
    }
  }
}
