// Color
$primaryColor: #6bbbae;
$mainDarkColor: #101820;
$mainLightColor: #ffffff;
$successColor: #5cc88c;
$errorColor: #cb2a39;
$dangerColor: #dd5f76;
$warningColor: #f7b500;
$lightTextColor: #ffffff;
$lightTextColor2: #a0a0a0;
$lightTextColor3: #929292;
$lightTextColor4: #ababab;
$pickTextColor: #88deda;
$primaryColorActive: #17dcdb;
$darkTextColor: #161f1e;
$darkBgColor: #121212;
$darkBgColor2: #000000;
$darkBgColor3: #2e2e2e;
$borderColor: #333333;
$cardColor: #3a3a3a;
$eventCardBgColor: #1f1f1f;
$inputBgColor: rgba(255, 255, 255, 0.3);
$darkLabelBgColor: rgba(255, 255, 255, 0.16);
$showMoreBgColor: rgba(255, 255, 255, 0.08);
$successBgColor: #223d2e;
$blackColor: #000000;
$disconnectBgColor: rgba(245, 245, 245, 0.24);
$yellowBurnedColorLight: #ffca7a;
$pickBgColor: #0f2324;
$yellowBurnedColor: #f7b500;
$greenPlanedColor: #5cc88c;
$grayPlanedColor: rgba(16, 24, 32, 0.1);
$cherryRed: #e2163b;
$primaryBlue: #1890ff;

// Font
$fontSizeBannerTitle: 47px;
$fontSizeXxxxl: 64px;
$fontSizeXxxl: 50px;
$fontSizeXxl: 33px;
$fontSizeXl: 24px;
$fontSizeLg: 20px;
$fontSizeMd: 16px;
$fontSizeSm: 14px;
$fontSizeXs: 12px;
$fontSizeXxs: 10px;
$titleFontSize: 40px;
$headlineBigFontSize: 48px;
$headline4FontSize: 32px;
$headline2FontSize: 21px;
$biggerFontSize: 17px;
$subtitle2FontSize: 16px;
$subtitle1FontSize: 15px;
$inputLabelSize: 13px;

// Animation
$defaultTransition: all 200ms ease;

// Media
$mobileWidth: 375px;
$mobileWidthL: 425px;
$tabletWidth: 768px;
$laptopWidth: 1024px;
$laptopWidthL: 1440px;
$desktopWidth: 2560px;

@mixin mobileS {
  @media (max-width: #{$mobileWidth}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobileWidthL}) {
    @content;
  }
}

@mixin mobileL {
  @media (max-width: #{$tabletWidth}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$laptopWidth}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptopWidthL}) {
    @content;
  }
}

@mixin laptopL {
  @media (max-width: #{$desktopWidth}) {
    @content;
  }
}
