.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  max-width: 700px;
  margin: auto;
  text-align: center;

  .messageBox {
    width: 550px;
    height: auto;
    padding: 24px 56px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(37px);
    border-radius: 8px;
  }
}

.description {
  margin-top: 10px;
}

.hidden {
  display: none;
}

.button {
  max-width: 300px;
  width: 100%;
  text-transform: none;
  border-radius: 8px;
  padding: 5px 20px;
}
