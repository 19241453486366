@use 'src/resources/styles/variables';

.icon {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px auto;
  text-align: center;
  font-size: variables.$fontSizeXxl;
  background-color: variables.$mainLightColor;
  border-radius: 50%;
  padding: 32px;

  img {
    width: 56px;
    // makes svg green
    filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
  }
}

.companyName {
  font-family: 'BreakersSlabW00 Light', serif;
  color: variables.$mainDarkColor;
  font-size: variables.$fontSizeXxl;
  text-align: center;
  margin-bottom: 6px;
}

.accountAddress {
  color: variables.$mainDarkColor;
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;

  img {
    // makes svg green
    filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
  }
}
