@use 'src/resources/styles/variables';
.container {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    h1 {
      font-size: 27px;
      line-height: 32px;
      letter-spacing: 0.25px;
      color: variables.$mainDarkColor;
      font-family: 'Breakers Slab W00', serif !important;
      font-weight: 400 !important;
    }
    p {
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin: 0px;
      color: variables.$mainDarkColor;
    }
    button {
      width: 180px;
      margin-top: 28px;
    }
  }

  .big {
    h1 {
      font-size: 32px;
      margin-bottom: 24px !important;
    }

    button {
      width: 180px !important;
    }
  }
}
