@use 'src/resources/styles/variables';

.chartLegend {
}

.colorRound {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 2px;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid variables.$mainLightColor;
    z-index: 1;
  }
}

.title {
  color: rgba(variables.$mainDarkColor, 0.6);
  font-size: variables.$fontSizeSm;
  font-weight: 500;
  margin-top: 2px;
}

.value {
  color: variables.$mainDarkColor;
  font-weight: 700;
  font-size: variables.$subtitle1FontSize;
  padding: 2px 0;
}

.middle {
  margin: 10px 0;
  .value {
    font-size: variables.$fontSizeMd;
  }
}

.large {
  margin: 20px 0;
  .value {
    font-size: variables.$fontSizeXxl;
  }
}
