@use 'src/resources/styles/variables';
.label {
  width: 100%;
  height: 48px;
  padding: 6.4px 15px;
  background-color: variables.$darkLabelBgColor;
  color: variables.$mainLightColor;
  border: none;
  border-radius: 8px;
  font-size: variables.$fontSizeSm;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  line-height: 35.2px;
  span {
    display: inline-block;
  }
}
