@use 'src/resources/styles/variables';

.gradient {
  background: linear-gradient(
    rgba(variables.$darkBgColor, 0.3) 0,
    rgba(variables.$darkBgColor, 0.9) 28vh,
    variables.$darkBgColor 34vh,
    variables.$darkBgColor 100%
  );
  padding: 0 48px;
}

.backgroundImage {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.backgroundGradient {
  width: 100%;
  height: 100%;
  //background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 80.83%, rgba(242,243,244) 95%);
}

.mainGradient {
  height: fit-content;
  z-index: 1;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  :global {
    .ant-layout {
      background: transparent !important;
    }
  }
}

.layout {
  :global {
    .ant-layout-sider {
      height: 100vh;
      position: fixed;
    }

    .ant-layout-sider,
    .ant-menu {
      padding-top: 16px;
    }

    .ant-layout-sider {
      min-width: 256px !important;
      background: url('../../../../../resources/images/bg/tristan-navigation.png') no-repeat;
      background-size: cover !important;
      width: 100%;

      .ant-layout-sider-children {
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .ant-layout-sider-collapsed {
      min-width: 90px !important;
      width: 100%;
    }

    .anticon {
      display: flex !important;
      align-items: center;
      justify-content: center !important;
      font-size: 18px;
      line-height: 64px;
      transition: color 0.3s;
      color: variables.$mainLightColor;

      &:hover {
        color: rgba(variables.$mainLightColor, 0.54);
      }
    }

    .ant-menu {
      padding: 24px 16px 0 16px;
      background: transparent;
    }

    .ant-menu-title-content {
      display: flex;
    }

    .ant-menu-item {
      display: flex !important;
      align-items: center;
      .ant-menu-title-content {
        a {
          width: 100%;
          color: variables.$mainLightColor !important;

          &:hover {
            opacity: 0.9 !important;
          }
        }
      }

      span.anticon {
        width: 24px;
        height: 24px;

        img {
          // make svg white
          filter: invert(100%);
        }
      }
    }

    .ant-menu-item-active {
      background-color: rgba(16, 24, 32, 0.3) !important;
      height: 48px !important;
      border-radius: 8px;

      span.anticon {
        img {
          // makes svg white
          filter: invert(100%);
        }
      }
    }

    .ant-menu-item-selected {
      background-color: rgba(16, 24, 32, 0.3) !important;
      height: 48px !important;
      border-radius: 8px;

      span.anticon {
        img {
          // makes svg green
          filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
        }
      }

      .ant-menu-title-content {
        font-weight: bold;

        a {
          color: variables.$primaryColor !important;
        }
      }

      .ant-menu-item-icon {
        color: variables.$primaryColor !important;
      }
    }

    .ant-menu-item-icon {
      font-size: variables.$fontSizeXl !important;
      padding-right: 16px;
    }

    .ant-menu-inline-collapsed {
      .ant-menu-item {
        .ant-menu-item-icon {
          position: absolute;
          height: 30px;
        }
        .ant-menu-title-content {
          position: absolute;
          left: -20px;
        }
      }

      .ant-menu-item-active {
        background-color: rgba(16, 24, 32, 0.3) !important;

        span.anticon {
          img {
            // makes svg green
            filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
          }
        }

        .ant-menu-title-content {
          font-weight: bold;

          a {
            color: variables.$primaryColor !important;
          }
        }

        .ant-menu-item-icon {
          color: variables.$primaryColor !important;
        }
      }

      .ant-menu-item-selected {
        background-color: variables.$primaryColor !important;
        box-shadow: 0px 8px 22px rgba(107, 187, 174, 0.6);

        span.anticon {
          img {
            // makes svg white
            filter: invert(100%);
          }
        }
      }
    }
  }
}

:global {
  .ant-layout-sider-collapsed ~ .ant-layout > .ant-layout-content {
    padding-left: 138px !important;
  }

  .ant-menu-inline-collapsed-tooltip {
    a {
      color: variables.$primaryColor;
    }
  }
}

.slider {
  z-index: 1;
}

.labelBtnContainer {
  margin: 16px 16px 24px 16px;

  div {
    margin-bottom: 16px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin: 16px 16px 30px 16px;

  .collapsedTrigger {
    display: none;
    position: absolute;
    top: 36px;
    right: -12px;
    border-radius: 50%;
    background-color: variables.$mainLightColor;
    width: 24px;
    height: 24px;

    span {
      height: 100%;
      align-content: center;

      img {
        height: 11px;
        width: 7px;
        // makes svg green
        filter: invert(65%) sepia(39%) saturate(359%) hue-rotate(121deg) brightness(97%) contrast(89%);
      }
    }
  }
}

.slider:hover {
  .collapsedTrigger {
    display: block;
  }
}

.logoContainerCollapsed {
  justify-content: center;
}

.content {
  flex: 1 1;
  padding: 0 48px 0 304px;
  overflow-x: hidden;
}

.collapsedLogo {
  font-family: 'Gotham Pro', sans-serif !important;
  font-weight: bold;
  color: variables.$lightTextColor;
  text-transform: uppercase;
  font-size: variables.$fontSizeLg !important;

  span {
    font-family: 'Gotham Pro', sans-serif !important;
    color: variables.$primaryColor;
    text-transform: uppercase;
    font-size: variables.$fontSizeLg !important;
  }
}

.white {
  color: variables.$mainLightColor;
}

.hide {
  display: none !important;
}

.externalButtons {
  bottom: 60px;
  left: 16px;
}

.externalButton {
  display: flex;
  align-items: center;
  margin: 10px auto;
  background: rgba(107, 187, 174, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  width: 224px;
  padding: 12px 16px;
  font-size: 15px;
  line-height: 18px;
  color: variables.$primaryColor;

  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: rgba(107, 187, 174, 0.03);
    color: variables.$primaryColor;
  }

  &.externalButtonCollapsed {
    width: 60px;
    .externalButtonText {
      display: none;
    }
  }
}

.externalButtonText {
  margin-left: 10px;
}
