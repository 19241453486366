@use 'src/resources/styles/variables';

* {
  font-family: 'Roboto Condensed', sans-serif !important;
}

html {
  height: 100vh;
  background-color: rgba(242, 243, 244);
}

body {
  background: transparent !important;
}

// Global Antd Styles

.ant-select-dropdown {
  backdrop-filter: blur(15px);
  background-color: variables.$inputBgColor;
  border-radius: 6px;
  color: variables.$lightTextColor !important;

  .ant-empty-image {
    display: none;
  }

  .ant-empty-description {
    color: variables.$lightTextColor !important;
  }
}

.ant-select-item {
  color: variables.$mainDarkColor !important;
  font-size: variables.$fontSizeSm;
  padding: 8px 10px;

  &.ant-select-item-option-active,
  &.ant-select-item-option-selected,
  &:hover {
    background-color: rgba(variables.$primaryColor, 0.2) !important;
    font-weight: 500;
  }

  .anticon.anticon-check {
    color: variables.$lightTextColor !important;
  }
}

.ant-form-item-explain {
  margin: 6px 14px;
  color: rgba(variables.$mainDarkColor, 0.6) !important;
  font-size: variables.$fontSizeXs;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.ant-form-item-has-error {
  .ant-form-item-explain {
    color: variables.$errorColor !important;
  }
}

.ant-tooltip-arrow {
  display: none !important;
}
