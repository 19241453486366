@use 'src/resources/styles/variables';

.button {
  background-color: variables.$mainLightColor;
  color: variables.$primaryColor;
  border: 1px solid variables.$primaryColor;
  border-radius: 4px;
  text-transform: none;
  font-size: variables.$fontSizeSm;
  font-weight: 700;
  letter-spacing: 1px;
  height: 48px;

  &:active,
  &:focus,
  &:hover {
    background-color: rgba(variables.$primaryColor, 0.1);
    color: variables.$primaryColor;
    border: 1px solid variables.$primaryColor;
    opacity: 0.9;
  }

  &:global(.small) {
    height: 36px;
  }

  &:global(.middle) {
    height: 40px;
  }
}

.shadow {
  box-shadow: 0 12px 24px 0 rgba(163, 242, 238, 0.24);
}
