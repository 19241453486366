@use 'src/resources/styles/variables';

.button {
  width: 100%;
  background-color: variables.$primaryColor;
  color: variables.$mainLightColor;
  border: none;
  border-radius: 4px;
  font-size: variables.$fontSizeSm;
  font-weight: 700;
  letter-spacing: 0.3px;
  height: 48px;
  box-shadow: 0 12px 24px 0 rgba(163, 242, 238, 0.24);

  &:hover {
    background-color: rgba(variables.$primaryColor, 0.6);
    color: variables.$mainLightColor;
  }

  &:active {
    background-color: transparent !important;
    color: variables.$primaryColor !important;
    border: 1px solid variables.$primaryColor !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: variables.$primaryColor;
    color: variables.$mainLightColor;
    border: none;
    box-shadow: 0 12px 24px 0 rgba(163, 242, 238, 0.24);
  }

  &:global(.middle) {
    height: 40px;
  }
  &:global(.small) {
    height: 36px;
  }
}
