@use '../../../../resources/styles/variables';

.container {
  background: linear-gradient(180deg, #f5f6f6 0%, rgba(255, 255, 255, 0) 33.44%),
    url('../../../../resources/images/bg/tristan-1.png');
  background-size: cover;
  background-position-y: 30%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  :global {
    .anticon {
      display: flex !important;
      align-items: center;
      justify-content: center !important;
      font-size: 18px;
      line-height: 64px;
    }
  }

  footer {
    width: 100% !important;
    left: 0;
  }
}

.content {
  flex: 1 1;
  padding: 0 90px 20px 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
