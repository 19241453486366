@use 'src/resources/styles/variables';

.container {
  width: 550px;
  max-width: 100vw;
  font-family: 'Breakers Slab W00', serif;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.title {
  font-size: variables.$fontSizeXxxxl;
  color: variables.$mainDarkColor;
  font-weight: 300;
  margin: 0;
}

.subtitle {
  font-size: variables.$fontSizeLg;
  font-weight: 300;
}

.message {
  margin: auto;
  max-width: 380px;
  font-size: variables.$fontSizeSm;
  font-weight: 300;
}

.button {
  width: 180px;
  margin: 40px 0;
}
